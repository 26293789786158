<template>
  <v-app>
    <v-main class="landingpage">
      <section>
        <div class="globe-header">
          <v-layout column align-center justify-center  class="white--text bg-gradient fill-height overflow-hidden">
            <!-- <iframe src="/globe/index.html" class="iframe-globe" title="Self Biography Globe" frameborder="0" allowtransparency="true"></iframe> -->

            <video width="100%" height="100%" muted autoplay loop playsinline>
              <source :src="require('@/assets/images/pages/landingpage/globe.mp4')" type="video/mp4">
              Your browser does not support the video tag.
            </video>

            <div class="globe-text">
              <div class="wrapper-logo">
                <img :src="require('@/assets/images/logos/selfbio-logo-6.png')" />
                <h2 class="app-title text--primary" style="color: #fff !important">
                  <span style="color: #9155fd;">SELF</span>BIOGRAPHY
                </h2>
              </div>
              <h1 class="white--text mb-2 display-1 text-xs-center txt-title-header">Record your own Biography & leave your Impression in the World</h1>
              <div class="white--text subheading mb-3 text-xs-center txt-subtitle">Everyone's life deserves a biography to share with loved ones and future generations.</div>
            </div>
          </v-layout>
          <div  style="position: absolute; bottom: 50px;z-index: 1;left: 50%;transform: translateX(-50%);">
            <div class="white--text subheading mb-3 text-xs-center txt-why-not">Why not do it yourself</div>
            <v-btn class="blue lighten-2 mt-5" dark large href="/auth" style="margin: 0 auto;display: block;line-height: 20px;">
              JOIN THE BETA PROGRAM FOR FREE
            </v-btn>
          </div>
        </div>
      </section>
      <section>
        <v-layout
          column
          wrap
          class="mt-10"
          align-center
          >
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout row wrap align-center>
                <div class="section">
                  <div class="container">
                    <div class="perspective-phone">
                      <h2 class="headline text-center">The best way to organize your memories and your amazing world</h2>
                      <img :src="require('@/assets/images/pages/landing-laptop.webp')" alt="Perspective computer" style="position:relative;top:-38px;">

                    </div>
                    <div class="row" style="position:relative;top:-60px;">
                      <div class="col-12 col-lg-4">
                        <div class="card features">
                          <div class="card-body">
                            <div class="media">
                              <span class="ti-face-smile gradient-fill ti-3x mr-3"></span>
                              <div class="media-body">
                                <h4 class="card-title">Simple and easy to use</h4>
                                <p class="card-text mt-2">
                                  The interface is simple and intuitive.<br />
                                  You can record memories with your mobile or any other media at your disposal in real time or access your photo libraries from your phone or computer.<br />
                                  You do not have to worry about file formats or data size, and everything is automatically optimized for you.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-4">
                        <div class="card features">
                          <div class="card-body">
                            <div class="media">
                              <span class="ti-settings gradient-fill ti-3x mr-3"></span>
                              <div class="media-body">
                                <h4 class="card-title">Customizable</h4>
                                <p class="card-text mt-2">
                                  You can use or edit the standard categories and create new ones of your own.
                                  You can edit any content at any time. You determine the content that you want to show or hide.<br />
                                  When it comes to content and content organization, you decide what best suits your story.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-4">
                        <div class="card features">
                          <div class="card-body">
                            <div class="media">
                              <span class="ti-lock gradient-fill ti-3x mr-3"></span>
                              <div class="media-body">
                                <h4 class="card-title">Private and Secure</h4>
                                <p class="card-text mt-2">
                                  With multi factor identification and Blockchain data backup. You are always the gatekeeper.<br />
                                  Share only what you want others to see. Selfbiography is privacy-centric and you are always the gate keeper.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </section>

      <section>
        <v-parallax :src="require('@/assets/images/pages/landingpage/3.webp')" height="651">
          <v-layout column align-center justify-center>
            <div class="headline white--text mb-3 text-xs-center txt-typing">The first of its kind, Self Biography is an archival private social media platform where you can document your life and publish an official biographical record that will be preserved for future generations.  You can record memories and life accomplishments using any form of media, which will be organized chronologically into a timeline. Self Biography is privacy-centric, data-secure and you are always the “gatekeeper” of the information that you put in. </div>
            <v-btn class="blue lighten-2 mt-5" dark large href="/auth" style="    position: absolute;bottom: 50px;">
              JOIN THE BETA PROGRAM FOR FREE
            </v-btn>
          </v-layout>
        </v-parallax>
      </section>
      <section class="landingpage">
        <v-container grid-list-md>
          <h2 class="headline text-center ma-10">Everything in one place in chronological order</h2>
          <v-layout row wrap>
            <v-flex>
              <v-card class="elevation-0 transparent">
                <v-card-text>
                  <v-timeline
                    align-top
                    :dense="$vuetify.breakpoint.smAndDown"
                  >
                    <v-timeline-item
                      v-for="(item, i) in items"
                      :key="i"
                      :color="item.color"
                      :icon="item.icon"
                      fill-dot
                    >
                      <v-card
                        :color="item.color"
                        dark
                      >
                        <v-card-title class="text-h6">
                          {{ item.title }}
                        </v-card-title>
                        <v-card-text class="white text--primary">
                          <p class="pt-5">{{ item.text }}</p>
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>

                  <h2 class="headline text-center pt-15" style="color: #633991 !important">Self Biography is not Facebook.</h2>
                  <div class="subheading mb-3 text-xs-center txt-subtitle text-justify">Self Biography is not Facebook, or anything like it. Self Biography is a conglomeration of private networks. All private and separated, there is no newsfeed, no opinionated comments, nor political discourse of any kind. The idea of Self Biography is to unite people through their shared memories rather than separate people by their opinions.</div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </section>
      <section class="section-carrousel">
        <v-carousel
          cycle
          :height="imgHeight"
        >
          <v-carousel-item
            v-for="([slide, text], i) in slides"
            :key="i"
            :src="require(`@/assets/images/pages/landingpage/${ slide }`)"
            color="#000000"
          >
            <h5 class="white--text mb-2 display-1 text-xs-center">{{ text }}</h5>
          </v-carousel-item>
        </v-carousel>
      </section>
    </v-main>
  </v-app>
</template>

<script>
import { mdiStar } from '@mdi/js';

export default {
  name: "App",
  components: {},
  data: function() {
    return {
      videoOptions: {
        autoplay: false,
        controls: true,
        sources: [
          {
            src:
              'https://www.youtube.com/embed/IMRJhk-4ik0',
              type: 'youtube'
          }
        ]
      },
      slides: [
        ['2.webp', 'My Pictures'],
        ['4.webp', 'My Places'],
        ['8.webp', 'My Diary'],
        ['9.webp', 'My Self'],
        ['7.webp', 'My Things'],
        ['10.webp', 'My Work'],
        ['11.webp', 'My Food'],
        ['12.webp', 'My Pets'],
        ['13.webp', 'My Soul'],
        ['14.webp', 'My Believes'],
        ['15.webp', 'My Travels'],
      ],
      items: [
        {
          icon: mdiStar,
          title: 'Secure & Private',
          text: 'Multifactor identification and Blockchain data backup - You are always the gatekeeper - Share only what you want others to see.'
        },
        {
          icon: mdiStar,
          title: 'Record your Biography',
          text: 'Text & pictures - Voice & video recording - Voice & video uploading. '
        },
        {
          icon: mdiStar,
          title: 'Personal & Professional',
          text: 'Personal & Professional modes to record your personal and professional accomplishments.'
        },
        {
          icon: mdiStar,
          title: 'Memory bits',
          text: 'Build your biography with Memory bits and connect with others by sharing, commenting, and adapting memories from other users.'
        },
        {
          icon: mdiStar,
          title: 'Memory connections',
          text: 'Connect your memory bits to others and allow other users to adopt them for themselves. Build a memory network of your connections and have others do the same.'
        },
        {
          icon: mdiStar,
          title: 'Gathers information',
          text: ' Everything that is written about you is gathered and integrated into Self Biography. Facebook - Instagram - Twitter - Webesearch and more.'
        },
        {
          icon: mdiStar,
          title: 'Chronological order',
          text: 'Everything is in one place in chronological order. Organize your memories by time and location - Real time data entry - Advance search function.'
        },
        {
          icon: mdiStar,
          title: 'Multiple formats and displays',
          text: 'Publish your Biography and make it public in multiple formats and displays. Paginated book publishing - PDF - Calendar timeline - Mosaic - Professional resume - 3D global view - Time machine.'
        },
        {
          icon: mdiStar,
          title: 'Private chat and video conferencing',
          text: 'Chat and video conferencing with family and friends as well as business associates in professional mode.'
        },
      ],
    };
  },
  methods: {
  },

  computed: {
    imgHeight: function() {
      return window.innerHeight * 0.70;
    }
  },
};
</script>

<style lang="scss">
.landingpage {
  .finedTitle {
    font-weight: 900;
    text-shadow: 2px 2px #000000;
  }

  .social-icon {
    font-size: 21px;
    color: white;
  }

  .theme--dark.v-btn.v-btn--has-bg {
    background: rgba(145, 85, 253, 1) !important;
  }

  .bg-gradient {
    background-image: -moz-linear-gradient( 135deg, rgba(60, 8, 118, 0.5) 0%, rgba(33, 13, 71, 0.5) 100%);
    background-image: -webkit-linear-gradient( 135deg, rgba(60, 8, 118, 0.5) 0%, rgba(33, 13, 71, 0.5) 100%);
    background-image: -ms-linear-gradient( 135deg, rgba(60, 8, 118, 0.5) 0%, rgba(33, 13, 71, 0.5) 100%);
    background-image: linear-gradient( 135deg, rgba(60, 8, 118, 0.5) 0%, rgba(33, 13, 71, 0.5) 100%);
  }

  .v-parallax__content {
    * {
      z-index: 2;
    }
    &:before, &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(106, 63, 185, 0.5);
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    &:after {
      background: rgba(0, 0, 0, 0.35);
    }
    .txt-typing {
      font-size: 1.85rem !important;
      width: 90%;
      max-width: 1200px;
      text-align: justify;
      font-family: 'Courier' !important;
      text-shadow: 5px 2px 5px black;
      font-weight: bold;
    }
  }

  .v-parallax__image-container {
    img {
      filter: grayscale(1);
    }
  }

  .container {
    max-width: 1140px
  }

  .v-toolbar {
    position: inherit;
  }
}

.v-main {
  padding: 0 !important;
}

.v-main:not(.landingpage) {
  padding-top: 12px !important;
  .boxed-container {
    max-width: 1240px;
    margin: 0 auto;
  }
}

.v-parallax__content {
  padding: 0 !important;
}

.card.features {
  border: 0;
  border-radius: 3px;
  box-shadow: 0px 5px 7px 0px rgb(0 0 0 / 4%);
  transition: all 0.3s ease;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;

  .card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
  }

  &:before {
    content: "";
    position: absolute;
    width: 3px;
    color: #fc73b4;
    background: -moz-linear-gradient(top, #9477b4 0%, #fc73b4 100%);
    background: -webkit-linear-gradient(top, #9477b4 0%, #fc73b4 100%);
    background: linear-gradient(to bottom, #9477b4 0%, #fc73b4 100%);
    top: 0;
    bottom: 0;
    left: 0;
  }
}

.landingpage {
  h2 {
    font-size: 45px;
    font-weight: 300;
    color: #633991;
    letter-spacing: -1px;
    margin-bottom: 1rem;
  }
  img {
    margin: 20px 0 50px;
    max-width: 100%;
  }
}

.globe-header {
  position: relative;
  background: #000;
  min-height: 690px;

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.30);
    width: 100vw;
    height: 100%;
    display: block;
  }

  .globe-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: center;
    width: 100%;

    .white--text {
      text-shadow: 0 0 20px #000;
    }
  }

  .v-btn__content {
    line-height: 42px;
  }
}

.iframe-video {
  width: 100%;
  min-height: 600px;
  margin: 100px 0;
}

.iframe-globe {
  width: 100%;
  height: 80vh;
  max-height: 100%;
}

video {
  max-height: 90vh;
  object-fit: cover;
}

.router-link-active:not([href="/"]) {
  border: none;
  border-radius: 0;
}

.wrapper-logo {
  img {
    width: 150px;
    height: 150px;
    filter: invert(1);
    margin: 0;
  }

  h2 {
    font-size: 45px !important;
    text-shadow: 0 0 20px black;
  }
}

.section-carrousel {
  .v-image__image {
    filter: grayscale(1);
  }
  .v-responsive__content {
    position: relative;
    &:before, &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(106, 63, 185, 0.5);
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    &:after {
      background: rgba(0, 0, 0, 0.35);
    }

    h5 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      width: 100%;
      text-align: center;
      font-size: 2.8rem !important;
      text-shadow: 0 0 10px #000;
      font-weight: 600;
      font-family: Courier !important;
      letter-spacing: -1px !important;
    }
  }
}

.features .media {
  min-height: 275px;
}

.txt-why-not {
  font-size: 25px !important;
  text-align: center;
}
.v-application .display-1.txt-title-header {
  font-size: 2.425rem !important;
}
.txt-subtitle {
  font-size: 20px !important;
}

.v-card__title {
  word-break: break-word;
}

.perspective-phone h2 {
  position: relative;
  top: 25px;
  z-index: 1;
}

@media(max-width: 940px) {
  .perspective-phone h2 {
    top: 0;
  }
  .txt-why-not {
    font-size: 15px !important;
  }
  .v-application .display-1.txt-title-header {
    font-size: 1.6rem !important;
    line-height: 1.8rem;
  }
  video {
    height: 90vh;
  }
  .landingpage img {
    max-width: 100%;
  }

  .landingpage .v-parallax__content {
    .txt-typing {
      font-size: 1rem !important;
    }
  }
  .v-parallax__image-container img {
    max-width: none;
  }

  .txt-subtitle {
    font-size: 15px !important;
  }
}
</style>
